.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
  & > h1 {
    font-size: 24px;
    line-height: 120%;
    font-weight: var(--fontWeightBold);
    color: var(--colorGrey900);
  }
  & > h2 {
    font-size: 20px;
    line-height: 120%;
    font-weight: var(--fontWeightBold);
    color: var(--colorGrey900);
  }
  & > h3 {
    font-size: 18px;
    line-height: 120%;
    font-weight: var(--fontWeightBold);
    color: var(--colorGrey900);
  }
}
